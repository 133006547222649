import React from "react";
import Layout from "@/components/Layout";
import { GTM } from "@/components/GTM";

const Career = () => {
  return (
    <Layout>
      <div className="min-h-screen grid items-center bg-background text-white">
        <div>
          <h1 className="text-center text-4xl">Careers Page</h1>
          <p className="text-center text-gray-400 mt-2">
            under construction, please check back later
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Career;

export const Head = () => {
  return (
    <>
      <GTM />
      <title>Career | Multiply</title>;
      <link rel="canonical" href="https://www.multiply.ai/" />
      <link rel="canonical" href="https://blog.multiply.ai/" />
    </>
  );
};
